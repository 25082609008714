@font-face {
font-family: 'benton';
src: url(/_next/static/media/9d108f2718a94536-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'benton';
src: url(/_next/static/media/5708aaa03a8906d0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'benton';
src: url(/_next/static/media/50f2c829317a26aa-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'benton';
src: url(/_next/static/media/233231a892af1b14-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'benton Fallback';src: local("Arial");ascent-override: 90.34%;descent-override: 24.50%;line-gap-override: 0.00%;size-adjust: 104.49%
}.__className_fbb132 {font-family: 'benton', 'benton Fallback'
}

@font-face {
font-family: 'mercury';
src: url(/_next/static/media/ae6df302240de80c-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'mercury';
src: url(/_next/static/media/c40ae38172916c83-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'mercury';
src: url(/_next/static/media/0ab4c57c842393e9-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'mercury Fallback';src: local("Arial");ascent-override: 83.92%;descent-override: 20.98%;line-gap-override: 20.98%;size-adjust: 95.33%
}.__className_3f288f {font-family: 'mercury', 'mercury Fallback'
}

